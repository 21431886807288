<template>
  <div class="pdp-sticky-cta-pricing">
    <div v-if="isLoading" class="skeleton-container">
      <v-skeleton-loader
        class="mb-2"
        type="heading"
        width="250"
      ></v-skeleton-loader>
      <v-skeleton-loader type="text" width="10%"></v-skeleton-loader>
      <v-skeleton-loader type="text" width="80%"></v-skeleton-loader>
      <v-skeleton-loader type="text" width="40%"></v-skeleton-loader>
    </div>
    <template v-else>
      <div class="main-pricing">฿{{ mainPricing | currency }}</div>
      <template>
        <div class="label-or">
          หรือ
        </div>
        <div class="headline-s">
          ฿{{ (mainPricing / INSTALLMENT.MAX) | currency }} / เดือน
          ผ่อน 0% นานสูงสุด {{ INSTALLMENT.MAX }} เดือน
        </div>
      </template>
      <v-btn
        text
        class="btn-link"
        @click="$emit('openPaymentMethod')"
      >
        ดูเพิ่มเติมเกี่ยวกับการผ่อนชำระ
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { INSTALLMENT } from '@/config/index'

export default {
  name: 'PDPPricing',
  data() {
    return {
      INSTALLMENT,
    }
  },
  computed: {
    ...mapState({
      pricing: state => state.cart.cartSummaryData,
      isLoading: state => state.cart.isLoading,
    }),

    mainPricing() {
      return this.pricing?.main_product_price ?? 0
    },
  },
}
</script>

<style lang="stylus" scoped>
.skeleton-container {
    min-height: 109px;
}
.pdp-sticky-cta-pricing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: .5rem;
    gap: 8px;

    .main-pricing {
        font-family: "SF Pro Text";
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.875rem;
        letter-spacing: .03rem;
    }

    .label-or {
        font-family: "SF Pro Text";
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: .875rem;
        color: #6e6e73;
    }

    .btn-link {
        padding 0 !important
        color: $color-bnn;
        cursor pointer

        &:hover:before {
            background: transparent !important
        }

        .v-icon {
            color: $color-bnn
        }
    }
}
</style>
